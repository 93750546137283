import Vue from 'vue'
import { IS_PROD_BUILD } from '@/utils/globals'
import * as Sentry from '@sentry/vue'

export type SentryT = typeof Sentry

Sentry.init({
  Vue,
  dsn: IS_PROD_BUILD
    ? 'https://1dae99cda04145458b64bafb5ce2dc3c@o532657.ingest.sentry.io/5651781'
    : undefined,
  logErrors: true,
  environment: process.env.NODE_ENV,
})

// Add globally
Vue.prototype.$sentry = Vue.$sentry = Sentry
