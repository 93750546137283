import { DevorItem } from '@/api-client/model/devor-item'
import { DevorOrderBase } from '@/api-client/model/devor-order-base'
import { FirestoreDevorOrder } from '@/interfaces/firestore'
// FIXME: Fix Jest imports
import { GroupingTagType } from '../api-client/model/grouping-tag-type'

interface ItemNote {
  title: string | null
  quantity: number | null
  showOnKDS: boolean
  notes: Array<string>
}

export interface TicketItem {
  title: string
  quantity: number
  showOnKDS: boolean
  subItems: Array<string>
}

export interface TicketItemGroup {
  groupingTag: string
  content: Array<TicketItem>
}

export interface TicketOrder {
  ticketItemGroups: Array<TicketItemGroup>
  notes: Array<ItemNote>
}

export function sortOrder(
  order: Pick<FirestoreDevorOrder, 'cart' | 'grouping_tags'>
): TicketOrder {
  /**
   * Sort and group an DevorOrder followinf the grouping tags
   *
   * @param {Pick<DevorOrderBase, 'cart' | 'grouping_tags'>} Order containing only the keys 'cart' and 'grouping_tags'
   * @return {TicketOrder} Order sorted and grouped
   */

  // Create a list of grouping tags that will be represented
  const groupingTagOrderedList: Array<string> = order.grouping_tags
    .filter(
      (groupingTag) =>
        groupingTag.tag_type == GroupingTagType.Product ||
        (groupingTag.tag_type == GroupingTagType.Option &&
          groupingTag.should_ungroup)
    )
    .map(({ object_id: id }) => id)
  // Create a dictionary of grouping tags to easily get their names
  const groupingTagDictionary = Object.fromEntries(
    order.grouping_tags.map((groupingTag) => [
      groupingTag.object_id,
      groupingTag,
    ])
  )

  // Create an empty ticketOrder object to return
  const ticketOrder: TicketOrder = {
    ticketItemGroups: groupingTagOrderedList.map((groupingTag) => ({
      groupingTag: groupingTag,
      content: Array<TicketItem>(),
    })),
    notes: [
      {
        title: null,
        quantity: null,
        notes: order.cart.special_instructions,
        showOnKDS: true,
      },
    ],
  }

  function handleItemTag(
    item: DevorItem,
    subItems = Array<DevorItem>(),
    multiplier = 1
  ) {
    const expectedSubitems = subItems.map(
      ({ operational_title }) => operational_title
    )

    const groupingTag = groupingTagDictionary[item.grouping_tag]
    switch (groupingTag.tag_type) {
      case GroupingTagType.Menu:
        return
      default: {
        // Find the item group for this grouping tag
        const itemGroup = ticketOrder.ticketItemGroups.find(
          (ticketItemGroup) =>
            ticketItemGroup.groupingTag === groupingTag.object_id
        )
        // Check if this item is already present in the group
        const existingItem = itemGroup?.content.find((ticketItem) => {
          return (
            ticketItem.title === item.operational_title &&
            ticketItem.subItems.length == expectedSubitems.length &&
            ticketItem.subItems.every((subItem) =>
              expectedSubitems.includes(subItem)
            )
          )
        })
        if (existingItem) {
          // If it's present, add the quantity
          existingItem.quantity += item.quantity * multiplier
        } else {
          // If not, append at the end of the group
          itemGroup?.content.push({
            title: item.operational_title,
            quantity: item.quantity * multiplier,
            subItems: subItems.map(
              ({ operational_title }) => operational_title
            ),
            showOnKDS: item.show_on_kds,
          })
        }
      }
    }
  }

  function orderItem(item: DevorItem, multiplier = 1): boolean {
    // Append extra instructions at the end
    if (item.extra_instructions) {
      ticketOrder.notes.push({
        title: item.operational_title,
        quantity: item.quantity,
        notes: item.extra_instructions,
        showOnKDS: item.show_on_kds,
      })
    }
    // Loop over modifier groups
    if (item.selected_modifier_groups) {
      const subitems = Array<DevorItem>()
      for (const modifierGroup of item.selected_modifier_groups) {
        for (const selectedItem of modifierGroup.selected_items || []) {
          const printed = orderItem(selectedItem, item.quantity * multiplier)
          if (!printed) {
            subitems.push(selectedItem)
          }
        }
      }
      handleItemTag(item, subitems, multiplier)
    } else {
      if (
        // If the item grouping tag should not be represented, pass
        groupingTagOrderedList.includes(
          groupingTagDictionary[item.grouping_tag].object_id
        )
      ) {
        // Otherwise add the item to ticketOrder
        handleItemTag(item, Array<DevorItem>(), multiplier)
      } else return false
    }
    return true
  }

  for (const element of order.cart.items) orderItem(element)
  // ticketOrder contains our orders

  // Sort orders alphabetically
  for (const group of ticketOrder.ticketItemGroups) {
    // Change the grouping tag ID for its name
    group.groupingTag = groupingTagDictionary[group.groupingTag].name
    for (const item of group.content) {
      // Sort subitems of each item
      item.subItems.sort((a, b) => a.localeCompare(b))
    }
    // Sort items of group
    group.content.sort((a, b) => {
      const distance = a.title.localeCompare(b.title)
      return distance === 0
        ? // If the titles are the same, put the item with the least subitems first
          a.subItems.length - b.subItems.length
        : // otherwise, if the titles are not the same, return the distance
          distance
    })
  }
  // Sort notes by title
  ticketOrder.notes.sort(({ title: titleA }, { title: titleB }) =>
    !titleA && !titleB
      ? // If both titles are undefined, return 0
        0
      : // Otherwise...
      titleA
      ? // If titleA is defined...
        titleB
        ? // and titleB is defined, return the string distance
          titleA.localeCompare(titleB)
        : // but titleB is not defined, put B before A
          1
      : // If titleA is not defined, put A before B
        -1
  )

  return ticketOrder
}

export interface ItemTitle {
  title: string
  quantity: number
  notes: null | Array<string>
  subItems: Array<ItemTitle>
}

export interface FormatedOrder {
  itemTitles: Array<ItemTitle>
  notes: Array<string>
}

export function formatOrder(
  order: DevorOrderBase | FirestoreDevorOrder
): FormatedOrder {
  /**
   * Simply extract title, quantity and notes from a DevorOrder.
   * Used to display and order without sorting and grouping
   *
   * @param {DevorOrderBase | FirestoreDevorOrder} Original order
   * @return {FormatedOrder} Order ready to be displayed
   */

  function extract(element: DevorItem): ItemTitle {
    return {
      title: element.operational_title,
      quantity: element.quantity,
      notes: element.extra_instructions ?? null,
      subItems: (element.selected_modifier_groups ?? []).flatMap(
        (modifierGroup) => (modifierGroup.selected_items ?? []).map(extract)
      ),
    }
  }

  const formatedOrder: FormatedOrder = {
    itemTitles: order.cart.items.map(extract),
    notes: order.cart.special_instructions,
  }

  return formatedOrder
}
